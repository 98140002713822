import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";
import banner from "../../assets/images/M2-Hero.jpg";

const BannerBackground = styled.section`
  background: url(${banner}) no-repeat center center;
  background-size: cover;
  padding: 60px 0;
  min-height: 150px;
  color: #ffffff;
  margin-bottom: 30px;
`;
const SearchQueryWrap = styled.div`
  width: 1200px;
  margin: 0 auto;
  padding: 0 60px;
  box-sizing: border-box;
`;
const SearchQueryInfo = styled.h1`
  font-size: 30px;
  font-weight: 300;
  margin: 7px 0px;
`;
const Breadcrumbs = styled.div`
  a {
    color: #ffffff;
    font-size: 14px;
  }
`;
const SearchQueryPlate = styled.section`
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffcb47;
  border-radius: 3px;
  max-width: 250px;
  margin-top: 20px;
`;
const JumboPlate = styled.div`
  font-size: 46px;
  color: #000000;
  font-family: UKNumberPlate;
`;

const SearchQueryBanner = ({ searchTerm = "", numberOfResults = 0 }) => {
  return (
    <BannerBackground>
      <SearchQueryWrap>
        <Breadcrumbs>
          <Link to="/">Home</Link> /
        </Breadcrumbs>
        <SearchQueryInfo>
          { numberOfResults ? (
            <Fragment>
              We've found
              <strong> {numberOfResults} </strong>
              matching your search.
            </Fragment>
          ) : (
            "Sorry, we couldn't find what you were looking for."
          )}
        </SearchQueryInfo>
        <SearchQueryPlate>
          <JumboPlate>{searchTerm.length ? searchTerm : "N/A"}</JumboPlate>
        </SearchQueryPlate>
      </SearchQueryWrap>
    </BannerBackground>
  );
};

SearchQueryBanner.propTypes = {
  numberOfResults: PropTypes.number,
  searchTerm: PropTypes.string,
};

export default SearchQueryBanner;
