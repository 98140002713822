import React from "react";
import styled from "styled-components";

const ClearButton = styled.button`
    color: ${props => props.sideBar ? '#5c636e' : '#ffffff'};
    opacity: ${props => props.selected ? '1' : '0.7'};
    font-size: 14px;
    text-transform: uppercase;
    background: none;
    margin: 5px 0;
    border: 1px solid ;
    border-color: ${props => props.selected && props.sideBar ? '#5c636e'
    : props.selected ? '#ffffff'
    : 'transparent'};
    border-radius: 3px;
    padding: ${props => props.sideBar ? "0 8px 4px 4px" : "3px 10px 8px"};
    font-weight: ${props => props.sideBar ? "700" : "normal"};
    transition: all 0.2s ease-out;
    &:focus {
        outline: none;
    }
    &:hover {
        cursor:pointer;
    }
`;

const FilterButton = ({onClick, selected, sideBar, children}) => {
  return (
    <ClearButton
      onClick={onClick}
      selected={selected}
      sideBar={sideBar}
    >
      {children}
    </ClearButton>
  );
};

export default FilterButton;
