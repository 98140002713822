import React from "react";
import styled from "styled-components";
import SecondaryButton from "../Buttons/SecondaryButton";
import RegList from "./RegList";

const PlateHighlights = styled.section`
  text-align: left;
`;

const RegistrationList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const BrowseAll = styled(SecondaryButton)`
  display: block;
  margin: 30px auto 0;
`;

const RegistrationListing = ({ regList }) => {
  return (
    <PlateHighlights>
      <RegistrationList>
        <RegList data={regList} limit={8} />
      </RegistrationList>
      <BrowseAll>Browse all registrations</BrowseAll>
    </PlateHighlights>
  );
};

export default RegistrationListing;
