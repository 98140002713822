import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const CounterText = styled.span`
  color: #5d6d7c;
  font-size: 16px;
  font-weight: 900;
  padding: 20px 0;
  display: inline-block;
`;

const SearchResultCounter = ({ numberOfResults = 0 }) => {
  if (!numberOfResults) {
    return `0 results`;
  }
  return <CounterText>Showing 1 - {numberOfResults} of {numberOfResults} results</CounterText>;
};

SearchResultCounter.propTypes = {
  numberOfResults: PropTypes.number,
};

export default SearchResultCounter;
