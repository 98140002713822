import Fuse from "fuse.js";

const options = {
  keys: ["reg"],
  shouldSort: true,
};

const searchRegs = (searchTerm, list) => {
  const fuse = new Fuse(list, options);
  const result = fuse.search(searchTerm);

  return result;
}

export { searchRegs }