import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FilterButtons from "../FilterButtons/FilterButtons";


const SectionTitle = styled.h5`
    color: #444850;
    font-weight: 700;
    font-size: 15px;
    margin: 5px 0 20px;
`;

const SortResultsBy = ({filter, updateFilter}) => {
  return (
    <section>
      <SectionTitle>Sort results by</SectionTitle>
      <FilterButtons
        filter={filter}
        updateFilter={updateFilter}
        sideBar
        modifiers="sideBar"
      />
    </section>
  )
};
export default SortResultsBy;

SortResultsBy.propTypes = {
  filter: PropTypes.string,
  updateFilter: PropTypes.func,
};
