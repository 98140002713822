import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const IconItem = styled.i`
  transition: color 0.2s;
  font-size: ${props => (props.iconSmall ? `14px` : `24px`)};
`;

const Icon = props => {
  return (
    <IconItem
      className={`material-icons ${props.className ? props.className : ""}`}
      iconSmall={props.iconSmall}
      iconColor={props.iconColor}
    >
      {props.icon}
    </IconItem>
  );
};

export default Icon;

Icon.propTypes = {
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  iconSmall: PropTypes.number
};
