import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import banner from "../../assets/images/M2-Hero.jpg";
import DisplayPlate from "../DisplayPlate/DisplayPlate";
import Icon from "../Icon/Icon";

const HeroBanner = styled.section`
  background: url(${banner}) no-repeat center center;
  background-size: cover;
  padding: 80px 0;
  min-height: 450px;
`;
const HeroWrap = styled.div`
  width: 1200px;
  margin: 0 auto;
`;

const SearchWrap = styled.section`
  width: 550px;
  color: #ffffff;
  text-align: left;
  padding: 0 60px;
`;
const HeroIntro = styled.p`
  margin: 0 0 10px 0;
`;
const HeroTagLine = styled.h3`
  margin: 0 0 15px 0;
  font-size: 36px;
  font-weight: 300;
`;
const SearchPlate = styled.section`
  background: #ffcb47;
  display: inline-flex;
  height: 100px;
  width: 400px;
  border-radius: 3px;
  margin-bottom: 30px;
`;
const SearchInput = styled.input`
  border: 0;
  background: none;
  font-size: 65px;
  width: 320px;
  text-align: center;
  color: #000000;
  font-family: UKNumberPlate;
  :focus {
    outline: none;
  }
  ::placeholder {
    color: #9f8440;
  }
`;
const SearchButton = styled.button`
  background: none;
  border: none;
  text-align: center;
  width: 100%;
  padding: 0;
  box-shadow: 0px 0px 30px -6px rgba(0, 0, 0, 0.5);
  :focus {
    outline: none;
  }
  :hover {
    cursor: pointer;
  }
`;
const PlateStyles = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const AlternateOptions = styled.p`
  font-weight: 500;
  margin-bottom: 20px;
`;

const SearchIcon = styled(Icon)`
  font-size: 56px;
`;

const RegistrationSearch = ({
  onSearchChange,
  onSearchClick,
  searchTerm
}) => {
  return (
    <HeroBanner>
      <HeroWrap>
        <SearchWrap>
          <HeroIntro>The UK's personal registration marketplace</HeroIntro>
          <HeroTagLine>
            Find your <strong>perfect registration</strong>
          </HeroTagLine>
          <SearchPlate>
            <SearchInput
              value={searchTerm}
              type="text"
              placeholder="SEARCH"
              onChange={onSearchChange}
            />
            <SearchButton onClick={onSearchClick}>
              <SearchIcon
                icon="search"
              />
            </SearchButton>
          </SearchPlate>
          <AlternateOptions>Or browse by style</AlternateOptions>
          <PlateStyles>
            <DisplayPlate>AB12 ABC</DisplayPlate>
            <DisplayPlate>A123 ABC</DisplayPlate>
            <DisplayPlate>ABC 123</DisplayPlate>
          </PlateStyles>
        </SearchWrap>
      </HeroWrap>
    </HeroBanner>
  );
};

RegistrationSearch.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
  onSearchClick: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired
};

export default RegistrationSearch;
