import React from "react";
import styled from "styled-components";

const ClearButton = styled.button`
  color: ${props => (props.colour ? COLOUR_MAP[props.colour] : "#5d6d7c")};
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 30px;
  font-weight: 600;
  border: none;
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
`;

const COLOUR_MAP = {
  blue: "#008bea",
  yellow: "#ffcb47"
};

const GhostButton = props => {
  return (
    <ClearButton className={props.className} colour={props.colour}>
      {props.children}
    </ClearButton>
  );
};

export default GhostButton;
