import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-simple-flex-grid";
import {
  withRouter
} from "react-router-dom";
import "react-simple-flex-grid/lib/main.css";
import FILTER_TYPES from "../constants/filterTypes";
import "../App.css";
import Header from "../components/Header/Header";
import Container from "../components/Container/Container";
import RegistrationSearch from "../components/HeroBanner/RegistrationSearch";
import RegistrationListing from "../components/RegistrationListing/RegistrationListing";
import FilterButtons from "../components/FilterButtons/FilterButtons";
import BannerColumn from "../components/BannerColumn/BannerColumn";
import Footer from "../components/Footer/Footer";
import Firebase from "../firebase";
import { searchRegs } from "../utils/searchingUtils";

class Homepage extends Component {
  static propTypes = {
    history: PropTypes.object,
  };

  state = {
    filter: FILTER_TYPES.add,
    regList: [],
    searchTerm: "",
  };

  componentDidMount() {
    Firebase.getAllAvailableRegs(regList => {
      this.setState({
        regList
      });
    });
  }

  updateFilter = filter => {
    this.setState({
      filter
    });
  };

  handleSearchClick = () => {
    const {
      regList,
      searchTerm,
     } = this.state;
    const { history } = this.props;

    history.push("search-results", {
      searchTerm,
      regList: searchRegs(searchTerm, regList)
    });
  };

  handleSearchChange = ({ target: { value: searchTerm } }) => {
    this.setState({
      searchTerm
    });
  };

  render() {
    const {
      filter,
      regList,
      searchTerm,
    } = this.state;

    return (
      <React.Fragment>
        <Header />
        <RegistrationSearch
          searchTerm={searchTerm}
          onSearchClick={this.handleSearchClick}
          onSearchChange={this.handleSearchChange}
        />
        <Container offsetTop={-94}>
          <Row gutter={40}>
            <Col span={9}>
              <FilterButtons
                filter={filter}
                updateFilter={this.updateFilter}
              />
              <RegistrationListing regList={regList} />
            </Col>
            <Col span={3}>
              <BannerColumn />
            </Col>
          </Row>
        </Container>
        <Footer />
      </React.Fragment>
    );
  }
}

export default withRouter(Homepage);
