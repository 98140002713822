import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import * as Pages from "./pages";

// TODO: create pageUrls constant
const Routes = props => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/home" exact component={Pages.Homepage} />
        <Route exact path="/" component={Pages.Homepage} />
        <Route path="/search-results" component={Pages.SearchResults} />
        <Route path="/login-signup" component={Pages.LoginSignup} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
