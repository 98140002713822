import React from 'react';
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import FILTER_TYPES from "../../constants/filterTypes";
import FilterButton from "../Buttons/FilterButton";
import Icon from "../Icon/Icon";

const Filters = styled.section`
    margin-bottom: 10px;
    ${props => props.sideBar && `
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `}
`;

const CommonIconStyles = css`
  font-size: 18px;
  margin-right: 10px;
  position: relative;
  top: 4px;
`;

const FilterIconAdd = styled(Icon)`
  ${CommonIconStyles}
  color: #008bea;
`;

const FilterIconPopular = styled(Icon)`
  ${CommonIconStyles}
  color: #ff7c47;
`;

const FilterIconPriceDesc = styled(Icon)`
    ${CommonIconStyles}
    color: #c63131;
`;

const FilterIconPriceAsc = styled(Icon)`
    ${CommonIconStyles}
    color: #6bc631;
`;

const FilterButtons = (
  {
    filter,
    sideBar,
    updateFilter
  }) => {
  return (
    <Filters sideBar={sideBar}>
      <FilterButton
        onClick={() => updateFilter(FILTER_TYPES.add)}
        selected={filter === FILTER_TYPES.add}
        sideBar={sideBar}
      >
        <FilterIconAdd icon="add_to_photos" />
        Newly Listed
      </FilterButton>
      <FilterButton
        onClick={() => updateFilter(FILTER_TYPES.popular)}
        selected={filter === FILTER_TYPES.popular}
        sideBar={sideBar}
      >
        <FilterIconPopular icon="whatshot" />
        Popular
      </FilterButton>
      <FilterButton
        onClick={() => updateFilter(FILTER_TYPES.priceAsc)}
        selected={filter === FILTER_TYPES.priceAsc}
        sideBar={sideBar}
      >
        <FilterIconPriceAsc icon="trending_up" />
        Price Low to High
      </FilterButton>
      <FilterButton
        onClick={() => updateFilter(FILTER_TYPES.priceDesc)}
        selected={filter === FILTER_TYPES.priceDesc}
        sideBar={sideBar}
      >
        <FilterIconPriceDesc icon="trending_down" />
        Price High to Low
      </FilterButton>
    </Filters>
  )
};

FilterButtons.propTypes = {
  filter: PropTypes.string.isRequired,
  sideBar: PropTypes.bool,
  updateFilter: PropTypes.func.isRequired,
};

export default FilterButtons
