import React from "react";
import styled from "styled-components";

const RoundedButton = styled.button`
  background-color: #ffffff;
  color: #5d6d7c;
  border: 1px solid #5d6d7c;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 30px;
  font-weight: 600;
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
`;

const SecondaryButton = props => {
  return (
    <RoundedButton className={props.className}>{props.children}</RoundedButton>
  );
};

export default SecondaryButton;
