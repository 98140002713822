import firebase from "firebase";

const PROJECT_ID = "reggo-700";
const API_KEY = "AIzaSyCVBzk9Q-YTdZHHv6VbQHrQByyomnQSv2M";
const DATABASE_URL = "https://reggo-700.firebaseio.com/";

class Fire {
  constructor() {
    this.init();
  }

  init = () => {
    firebase.initializeApp({
      apiKey: API_KEY,
      authDomain: `${PROJECT_ID}.firebaseapp.com`,
      databaseURL: DATABASE_URL,
      projectId: PROJECT_ID,
      storageBucket: `${PROJECT_ID}.appspot.com`
    });
  };

  getAllAvailableRegs = callback => {
    const ref = firebase.database().ref("regs");

    return ref.on("value", snapshot => {
      const value = snapshot.val();

      if (value === null) {
        return callback([]);
      }

      return callback(value);
    });
  };
}

export default new Fire();
