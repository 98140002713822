import React, {Component} from "react";
import PropTypes from "prop-types";
import {Row, Col} from "react-simple-flex-grid";
import Header from "../components/Header/Header";
import FILTER_TYPES from "../constants/filterTypes";
import Footer from "../components/Footer/Footer";
import SearchQueryBanner from "../components/SearchQueryBanner/SearchQueryBanner";
import RegistrationListing from "../components/RegistrationListing/RegistrationListing";
import Container from "../components/Container/Container";
import SearchResultCounter from "../components/SearchResultCounter/SearchResultCounter";
import SearchFilters from "../components/SearchFilters/SearchFilters";

class SearchResults extends Component {
  static propTypes = {
    location: PropTypes.object,
  };

  state = {
    filter: FILTER_TYPES.add
  };

  updateFilter = filter => {
    this.setState({
      filter,
    });
  };

  render() {
    const {filter} = this.state;
    const {
      location: {
        state: {
          regList = [],
          searchTerm = "",
        }
      }
    } = this.props;
    const numberOfResults = regList.length;

    return (
      <React.Fragment>
        <Header />
        <SearchQueryBanner
          numberOfResults={numberOfResults}
          searchTerm={searchTerm}
        />
        <Container>
          <Row gutter={40}>
            <Col span={3}>
              <SearchFilters
                filter={filter}
                updateFilter={this.updateFilter}
              />
            </Col>
            <Col span={9}>
              <SearchResultCounter
                numberOfResults={numberOfResults}
              />
              <RegistrationListing regList={regList} />
            </Col>
          </Row>
        </Container>
        <Footer />
      </React.Fragment>
    );
  }
}

export default SearchResults;
