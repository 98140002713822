import React, {Component} from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Container from "../components/Container/Container";

class SearchResults extends Component {

  render() {
    return (
      <React.Fragment>
        <Header />
        <Container>
          <h1>Login Page</h1>
        </Container>
        <Footer />
      </React.Fragment>
    );
  }
}

export default SearchResults;
