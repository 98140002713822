import React, {Component} from 'react';
import styled from 'styled-components';

const FilterPrice = styled.section`
    margin: 0 0 40px;
`;
const SectionTitle = styled.h5`
    color: #444850;
    font-weight: 700;
    font-size: 15px;
    margin: 0;
`;
const TitleBar = styled.div`
    display: flex;
    flex-direction: row;
    margin: 5px 0 20px;
`;
const FilterField = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin: 10px 0;
`;
const FilterLabel = styled.label`
    justify-self: flex-start;
    display: flex;
    width: 60px;
    font-size: 13px;
    font-weight: 900;
    color: #5c636e;
`;
const FilterInput = styled.input`
    border: none;
    background: transparent;
    height: 40px;
    width: 100%;
    padding-left: 30px;
    color: #444850;
    font-size: 18px;
    &:focus {
      outline: none;
    }
`;
const ResetButton = styled.button`
    background: none;
    border: none;
    color: #008bea;
    font-weight: 700;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    flex-grow: 1;
    text-align: right;
    padding-right: 0;
     &:focus {
      outline: none;
    }
`;
const FilterInputBorder = styled.span`
    border: 1px solid #c7c8cb;
    border-radius: 3px;
    flex: 1;
    position: relative;
    &:before {
      position: absolute;
      left: 12px;
      top: 12px;
      content: "£";
      color: #c8c9cc;
      font-weight: 700;
      font-size: 14px;
    }
`;

class FilterByPrice extends Component {

  state = {
    maxPrice: 0,
    minPrice: 0,
  };

  resetState = () => {
    this.setState({
      maxPrice: 0,
      minPrice: 0,
    })
  }

  updateInput = param => e => {
    this.setState({
      [param]: parseInt(e.target.value, 10)
    })
  };

  render() {
    const {
      maxPrice,
      minPrice,
    } = this.state;

    return (
      <FilterPrice>
        <TitleBar>
          <SectionTitle>Filter by Price</SectionTitle>
          <ResetButton onClick={this.resetState}>Reset</ResetButton>
        </TitleBar>
        <FilterField>
          <FilterLabel
            id="lblMinPrice"
            htmlFor="minPrice"
          >
            From
          </FilterLabel>
          <FilterInputBorder>
            <FilterInput
              id="inputMin"
              name="minPrice"
              type="text"
              placeholder=""
              value={minPrice > 0 ? minPrice : ""}
              onChange={this.updateInput("minPrice")}
            />
          </FilterInputBorder>
        </FilterField>
        <FilterField>
          <FilterLabel
            id="lblMaxPrice"
            htmlFor="maxPrice"
          >
            To
          </FilterLabel>
          <FilterInputBorder>
            <FilterInput
              id="inputMax"
              name="maxPrice"
              type="text"
              placeholder=""
              value={maxPrice > 0 ? maxPrice : ""}
              onChange={this.updateInput("maxPrice")}
            />
          </FilterInputBorder>
        </FilterField>
      </FilterPrice>
    );
  }
}

export default FilterByPrice;
