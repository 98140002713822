import React from "react";
import styled from "styled-components";
import PrimaryButton from "../Buttons/PrimaryButton";
import banner from "../../assets/images/Banner-BKG.jpg";
import Icon from "../Icon/Icon";

const Column = styled.section`
  text-align: left;
  margin-top: 44px;
  box-sizing: border-box;
`;
const BannerGrey = styled.div`
  background: #5c636e;
  color: white;
  padding: 30px 25px;
  border-radius: 3px;
  margin-bottom: 30px;
`;
const BannerBkgImg = styled.div`
  background: url(${banner}) no-repeat center center;
  color: white;
  padding: 30px 25px;
  border-radius: 3px;
`;
const BannerHeadline = styled.h4`
  text-transform: uppercase;
  font-size: 14px;
  margin: 0;
  font-weight: 900;
`;
const BannerAccent = styled.h1`
  text-transform: uppercase;
  font-size: 26px;
  margin: 3px 0 0;
  font-weight: 800;
`;
const BannerList = styled.ul`
  margin: 30px 0 30px 28px;
  padding: 0;
  list-style: none;
`;
const BannerListItem = styled.li`
  font-size: 16px;
  margin: 15px 0;
  position: relative;
  :before {
    content: "\\e876";
    font-family: "Material Icons";
    display: inline-block;
    position: absolute;
    top: 0;
    left: -30px;
    font-weight: 900;
  }
`;
const ActionButtonIcon = styled(Icon)`
  font-size: 30px;
  line-height: 0px;
  position: relative;
  top: 9px;
  right: -5px;
`;

const BannerColumn = () => {
  return (
    <Column>
      <BannerGrey>
        <BannerHeadline>Sell your Registration</BannerHeadline>
        <BannerAccent>For Free</BannerAccent>
        <BannerList>
          <BannerListItem>Quick and Easy to list</BannerListItem>
          <BannerListItem>Contact information stays private</BannerListItem>
          <BannerListItem>No Listing or seller fees</BannerListItem>
        </BannerList>
        <PrimaryButton jumbo={true} colour="yellow">
          Create your account <ActionButtonIcon icon="chevron_right" />
        </PrimaryButton>
      </BannerGrey>
      <BannerBkgImg>
        <BannerHeadline>Sell your Registration</BannerHeadline>
        <BannerAccent>For Free</BannerAccent>
        <BannerList>
          <BannerListItem>Quick and Easy to list</BannerListItem>
          <BannerListItem>Contact information stays private</BannerListItem>
          <BannerListItem>No Listing or seller fees</BannerListItem>
        </BannerList>
        <PrimaryButton jumbo={true} colour="white">
          Create your account <ActionButtonIcon icon="chevron_right" />
        </PrimaryButton>
      </BannerBkgImg>
    </Column>
  );
};

export default BannerColumn;
