import React from "react";
import logo from "../../assets/images/reggo-logo.svg";
import styled from "styled-components";

const FooterSection = styled.footer`
  padding-top: 30px;
`;

const FooterMenu = styled.section`
  background: #5c636e;
  color: #ffffff;
  padding: 50px 0;
`;
const FooterMenuWrap = styled.div`
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
`;
const FooterMenuCol = styled.div`
  width: 25%;
  text-align: left;
  :not(:first-child) {
    border-left: 1px solid #757e8c;
  }
`;
const FooterList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 0 20px 25px;
`;
const FooterListItem = styled.li`
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 12px;
`;
const FooterListLink = styled.a`
  color: #cbd4e1;
  text-decoration: none;
`;
const CopyrightMenu = styled.section`
  background: #393e46;
  color: white;
  text-align: center;
`;
const CopyrightText = styled.p`
  margin: 0;
  padding: 20px 0;
  font-size: 12px;
  color: #cbd4e1;
`;
const StyledLogo = styled.img`
  width: 100px;
`;
const SocialTitle = styled.h5`
  margin: 0 0 0 25px;
  text-transform: uppercase;
  font-size: 14px;
`;
const Spacer = styled.span`
  display: inline-block;
  margin: 0 20px;
`;

const SocialLinks = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #cbd4e1;
  margin: 20px 0 0 25px;
`;
const SocialIcon = styled.span`
  font-size: 25px;
  margin-right: 20px;
`;

const Footer = () => {
  return (
    <FooterSection>
      <FooterMenu>
        <FooterMenuWrap>
          <FooterMenuCol>
            <StyledLogo src={logo} />
          </FooterMenuCol>
          <FooterMenuCol>
            <FooterList>
              <FooterListItem>
                <FooterListLink href="/">About Us</FooterListLink>
              </FooterListItem>
              <FooterListItem>
                <FooterListLink href="/">Contact Us</FooterListLink>
              </FooterListItem>
              <FooterListItem>
                <FooterListLink href="/">Careers</FooterListLink>
              </FooterListItem>
              <FooterListItem>
                <FooterListLink href="/">Media Center</FooterListLink>
              </FooterListItem>
            </FooterList>
          </FooterMenuCol>
          <FooterMenuCol>
            <FooterList>
              <FooterListItem>
                <FooterListLink href="/">
                  Transfering a Registration
                </FooterListLink>
              </FooterListItem>
              <FooterListItem>
                <FooterListLink href="/">Buying a Registration</FooterListLink>
              </FooterListItem>
              <FooterListItem>
                <FooterListLink href="/">Preparing to Sell</FooterListLink>
              </FooterListItem>
            </FooterList>
          </FooterMenuCol>
          <FooterMenuCol>
            <SocialTitle>Follow Us</SocialTitle>
            <SocialLinks>
              <SocialIcon className="socicon socicon-facebook" />
              <SocialIcon className="socicon socicon-twitter" />
              <SocialIcon className="socicon socicon-instagram" />
            </SocialLinks>
          </FooterMenuCol>
        </FooterMenuWrap>
      </FooterMenu>
      <CopyrightMenu>
        <CopyrightText>
          &copy; Copyright Reggo {new Date().getFullYear()}, All rights reserved{" "}
          <Spacer>|</Spacer> Privacy Policy <Spacer>|</Spacer>
          Terms &amp; Conditions
        </CopyrightText>
      </CopyrightMenu>
    </FooterSection>
  );
};

export default Footer;
