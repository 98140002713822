import React from "react";
import styled from "styled-components";

const PageContainer = styled.div`
  width: 1200px;
  margin: 0 auto;
  margin-top: ${props => (props.offsetTop ? `${props.offsetTop}px` : "unset")};
`;

const Container = props => {
  return (
    <PageContainer offsetTop={props.offsetTop}>{props.children}</PageContainer>
  );
};

export default Container;
