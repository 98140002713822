import React from "react";
import styled from "styled-components";

const RoundedButton = styled.button`
  background-color: ${props =>
    props.colour ? COLOUR_MAP[props.colour] : "#008bea"};
  color: ${props =>
    props.colour === "yellow"
      ? "#000000"
      : props.colour === "white"
      ? "#008bea"
      : "#ffffff"};
  font-size: 16px;
  padding: ${props => (props.jumbo ? "15px 20px" : "10px 20px")};
  border-radius: 30px;
  font-weight: 600;
  border: none;
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
`;

const COLOUR_MAP = {
  blue: "#008bea",
  yellow: "#ffcb47",
  white: "#ffffff"
};

const PrimaryButton = props => {
  return (
    <RoundedButton
      className={props.className}
      jumbo={props.jumbo}
      colour={props.colour}
    >
      {props.children}
    </RoundedButton>
  );
};

export default PrimaryButton;
