import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import Icon from '../Icon/Icon';
import SortResultsBy from "../SortResultsBy/SortResultsBy";
import FilterByPrice from "../FilterByPrice/FilterByPrice";
import FilterByStyle from "../FilterByStyle/FilterByStyle";

const FilterColumn = styled.section`
  background: #ffffff;
  padding: 15px 20px;
`;
const FilterIcon = styled(Icon)`
  position: relative;
  top: 4px;
  margin-right: 5px;
`;
const ColumnTitle = styled.section`
  color: #444850;
  font-weight: 900;
  font-size: 17px;
`;
const ColumnSubHeading = styled.p`
  color: #5c636e;
  font-weight: 600;
  font-size: 13px;
  margin: 5px 0 40px 3px;
`;

const SearchFilters = ({filter, updateFilter}) => {
  return (
    <FilterColumn>
      <ColumnTitle>
        <FilterIcon icon="filter_list" />
        Narrow your search
      </ColumnTitle>
      <ColumnSubHeading>
        Filter by style, price and more...
      </ColumnSubHeading>
      <FilterByStyle />
      <FilterByPrice />
      <SortResultsBy
        filter={filter}
        updateFilter={updateFilter}
      />
    </FilterColumn>
  )
};

export default SearchFilters;

SearchFilters.propTypes = {
  filter: PropTypes.string,
  updateFilter: PropTypes.func,
};
