import React from "react";
import PropTypes from "prop-types";
import RegListItem from "./RegListItem";

const setLimit = props => {
  if (props.limit) {
    return props.data.slice(0, props.limit);
  }
  return props.data;
};

const RegList = props => {
  const data = setLimit(props);
  return data.map(data => <RegListItem key={data.id} {...data} />);
};

export default RegList;

RegList.propTypes = {
  data: PropTypes.array.isRequired,
  limit: PropTypes.number
};
