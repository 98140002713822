import React from "react";
import styled, { css } from "styled-components";
import DisplayPlate from "../DisplayPlate/DisplayPlate";
import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";

const RegistrationListItem = styled.li`
  border: 1px solid #ededed;
  background-color: #ffffff;
  padding: 20px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  justify-content: space-between;
  border-radius: 3px;
`;

const RegistrationListActions = styled.div`
  button:not(:last-child) {
    margin-right: 15px;
  }
`;
const Price = styled.div`
  color: #444850;
  font-weight: 900;
  font-size: 18px;
`;

const MetaInfo = styled.div`
  display: flex;
  flex-direction: column;
  color: #5c636e;
  min-width: 90px;
`;
const CommonMetaStyles = css`
  display: block;
  margin: 2px 0;
  font-size: 13px;
  font-weight: 900;
`;
const MetaType = styled.span`
  ${CommonMetaStyles}
`;
const MetaPop = styled.span`
  ${CommonMetaStyles}
  color: #ff7c47;
  position: relative;
  padding-left: 16px;
  :before {
    position: absolute;
    left: -2px;
    content: "\\e80e";
    font-family: Material Icons;
    font-size: 15px;
  }
`;
const MetaPromo = styled.span`
  ${CommonMetaStyles}
  color: #c6c7ca;
  position: relative;
  padding-left: 16px;
  :before {
    position: absolute;
    left: -2px;
    content: "\\e838";
    font-family: Material Icons;
    font-size: 15px;
  }
`;

const RegListItem = data => {
  return (
    <RegistrationListItem>
      <DisplayPlate rear>{data.reg}</DisplayPlate>
      <MetaInfo>
        {data.promoted ? (
          <MetaPromo>Promoted</MetaPromo>
        ) : data.popular ? (
          <MetaPop>Popular</MetaPop>
        ) : (
          ""
        )}
        <MetaType>{data.regType}</MetaType>
      </MetaInfo>
      <Price>
        £
        {data.price}
      </Price>
      <RegistrationListActions>
        <SecondaryButton>View Details</SecondaryButton>
        <PrimaryButton>Contact Seller</PrimaryButton>
      </RegistrationListActions>
    </RegistrationListItem>
  );
};

export default RegListItem;
