import PropTypes from 'prop-types';
import React from "react";
import styled from "styled-components";

const Plate = styled.div`
  width: ${props => (props.tiny ? "100px" : "160px")};
  height: ${props => (props.tiny ? "35px" : "50px")};
  background: ${props => (props.rear ? "#ffcb47" : "#ffffff")};
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${props => (props.tiny ? "20px" : "30px")};
  font-weight: 800;
  border-radius: 3px;
  font-family: UKNumberPlate;
`;

const DisplayPlate = ({tiny, rear, children}) => {
  return (
    <Plate
      tiny={tiny}
      rear={rear}
    >
      {children}
    </Plate>
  );
};

DisplayPlate.propTypes = {
  tiny: PropTypes.bool,
  rear: PropTypes.bool,
  children: PropTypes.any,
};

export default DisplayPlate;
