import React from "react";
import logo from "../../assets/images/reggo-logo.svg";
import styled from "styled-components";
import PrimaryButton from "../Buttons/PrimaryButton";
import GhostButton from "../Buttons/GhostButton";
import Icon from "../../components/Icon/Icon";

const StyledHeader = styled.header`
  background-color: #ffffff;
`;
const HeaderWrap = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 25px 0;
`;
const HeaderButtonGroup = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const StyledLogo = styled.img`
  width: 120px;
`;
const StyledNav = styled.nav`
  flex-grow: 1;
`;
const NavList = styled.ul`
  padding: 0;
  margin: 0 0 0 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
const NavItem = styled.li`
  list-style: none;
  padding: 0 10px;
`;
const NavLink = styled.a`
  color: #5d6d7c;
  text-decoration: none;
  font-weight: 600;
`;
const IconButton = styled(GhostButton)`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
`;
const HeaderIcon = styled(Icon)`
  margin-right: 7px;
  display: inline-block;
`;

const Header = () => {
  return (
    <StyledHeader>
      <HeaderWrap>
        <div>
          <StyledLogo src={logo} />
        </div>
        <StyledNav>
          <NavList>
            <NavItem>
              <NavLink href="home">Home</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="link">Browse Registrations</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="link">How it works</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="link">Get in touch</NavLink>
            </NavItem>
          </NavList>
        </StyledNav>
        <HeaderButtonGroup>
          <IconButton>
            <HeaderIcon icon="person" /> Login
          </IconButton>

          <IconButton colour="blue">
            <HeaderIcon icon="person_add" />
            Create Account
          </IconButton>
          <PrimaryButton>Sell a Reg</PrimaryButton>
        </HeaderButtonGroup>
      </HeaderWrap>
    </StyledHeader>
  );
};

export default Header;
