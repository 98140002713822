import React, {Component} from "react";
import styled from 'styled-components';
import DisplayPlate from "../DisplayPlate/DisplayPlate";

const FilterStyle = styled.section`
    margin: 0 0 40px;
`;
const FilterStyleList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
`;
const FilterStyleItem = styled.li`
    border: 1px solid #c7c8cb;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
    margin: 10px 0;
    opacity: ${(props) => props.active ? "1" : "0.3"};
    &:hover {
     cursor: pointer;
    }
`;
const SectionTitle = styled.h5`
    color: #444850;
    font-weight: 700;
    font-size: 15px;
    margin: 0;
`;
const TitleBar = styled.div`
    display: flex;
    flex-direction: row;
    margin: 5px 0 20px;
`;
const ResetButton = styled.button`
    background: none;
    border: none;
    color: #008bea;
    font-weight: 700;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    flex-grow: 1;
    text-align: right;
    padding-right: 0;
     &:focus {
      outline: none;
    }
`;
const StyleStats = styled.div`
    display: flex;
    flex-direction: column;
    color: #5c636e;
    font-size: 13px;
    padding-left: 20px
`;
const StyleTitle = styled.span`
    font-size: 13px;
    font-weight: 900;
`;
const StyleCount = styled.span`
    font-size: 12px;
    font-weight: 500;
`;


// TODO: replace with constants data from FireBase and count from search results if available
const dummyData = {
  suffix: {
    title: "Suffix",
    example: "ABC 123 D",
    count: 3,
  },
  prefix: {
    title: "Prefix",
    example: "A 123 BCD",
    count: 5,
  },
  current: {
    title: "Current",
    example: "AB12 CDE",
    count: 12,
  },
  irish: {
    title: "Irish",
    example: "ABC 123",
    count: 6,
  },
};
const {suffix, prefix, current, irish} = dummyData;


class FilterByStyle extends Component {

  state = {
    prefixActive: false,
    suffixActive: false,
    currentActive: false,
    irishActive: false,
  };

  resetState = () => {
    this.setState({
      prefixActive: false,
      suffixActive: false,
      currentActive: false,
      irishActive: false,
    })
  }

  toggleState = (val) => {
    this.setState((prevState) => ({
      [val]: !prevState[val]
    }));
  };

  render() {
    const {
      prefixActive,
      suffixActive,
      currentActive,
      irishActive,
    } = this.state;

    return (
      <FilterStyle>
        <TitleBar>
          <SectionTitle>Filter by Style</SectionTitle>
          <ResetButton onClick={this.resetState}>Reset</ResetButton>
        </TitleBar>
        <FilterStyleList>
          <FilterStyleItem
            active={suffixActive}
            onClick={() => this.toggleState("suffixActive")}
          >
            <DisplayPlate
              tiny
              rear
            >
              {suffix.example}
            </DisplayPlate>
            <StyleStats>
              <StyleTitle>{suffix.title}</StyleTitle>
              <StyleCount>{`${suffix.count} Results`}</StyleCount>
            </StyleStats>
          </FilterStyleItem>
          <FilterStyleItem
            active={prefixActive}
            onClick={() => this.toggleState("prefixActive")}
          >
            <DisplayPlate
              tiny
              rear
            >
              {prefix.example}
            </DisplayPlate>
            <StyleStats>
              <StyleTitle>{prefix.title}</StyleTitle>
              <StyleCount>{`${prefix.count} Results`}</StyleCount>
            </StyleStats>
          </FilterStyleItem>
          <FilterStyleItem
            active={currentActive}
            onClick={() => this.toggleState("currentActive")}
          >
            <DisplayPlate
              tiny
              rear
            >
              {current.example}
            </DisplayPlate>
            <StyleStats>
              <StyleTitle>{current.title}</StyleTitle>
              <StyleCount>{`${current.count} Results`}</StyleCount>
            </StyleStats>
          </FilterStyleItem>
          <FilterStyleItem
            active={irishActive}
            onClick={() => this.toggleState("irishActive")}
          >
            <DisplayPlate
              tiny
              rear
            >
              {irish.example}
            </DisplayPlate>
            <StyleStats>
              <StyleTitle>{irish.title}</StyleTitle>
              <StyleCount>{`${irish.count} Results`}</StyleCount>
            </StyleStats>
          </FilterStyleItem>
        </FilterStyleList>
      </FilterStyle>
    )
  }
}

export default FilterByStyle;
